import youTubeIcon from '@/images/youtube.svg';
import fbIcon from '@/images/facebook.svg';
import xIcon from '@/images/x-icon.svg';
import instaIcon from '@/images/instagram.svg';

/**
 * @function socialLinks
 * @description provides social data in an array of object to iterate over
 * @param {Object} Object - data from show object
 * @param {String} Object.facebookAccountUrl
 * @param {String} Object.instagramAccountUrl
 * @param {String} Object.twitterAccountUrl
 * @param {String} Object.youtubeAccountUrl
 * @returns {Array} - array of social objects
 */
export default ({
	facebookAccountUrl,
	twitterAccountUrl,
	youtubeAccountUrl,
	instagramAccountUrl
}) => [
	{
		name: 'facebook',
		icon: fbIcon,
		url: facebookAccountUrl,
		trackingPrefix: 'facebook-share-icon'
	},
	{
		name: 'x',
		icon: xIcon,
		url: twitterAccountUrl,
		trackingPrefix: 'x-share-icon'
	},
	{
		name: 'instagram',
		icon: instaIcon,
		url: instagramAccountUrl,
		trackingPrefix: 'instagram-share-icon'
	},
	{
		name: 'youtube',
		icon: youTubeIcon,
		url: youtubeAccountUrl,
		trackingPrefix: 'youtube-share-icon'
	}
];
