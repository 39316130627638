import React, { Fragment, useRef, useEffect, useState } from 'react';
import Thumb from '@/components/thumb/Thumb';
import { useConsentStatus } from '@nbcnews/gpp-utilities';
import { useParamToggleContext } from '@/components/param-toggle/ParamToggleContext';
import Loading from '@/components/loading/Loading';
import parseHtml from '@/utils/parseHtml';
import { usePageContext } from '@/components/layout/page/PageContext';
import { object, oneOfType, string, bool } from 'prop-types';
import { generateCsid, getFreewheelUserId } from '@/components/video/videoHelpers';
import { getCookie } from '@/utils/cookies';
import NoWrap from '@/components/nowrap/NoWrap';
import { GammaPlayer } from '@nbcnews/gamma';
import './ShowHero.scss';
import '@/components/video/video-player/GammaPlayer.scss';

/**
 * @function A
 * @param {String} uri
 * @param {Object|String} children
 * @return {React.ReactElement}
 */
const A = ({ uri, children }) => <a href={uri}>{children}</a>;

A.propTypes = {
	uri: string,
	children: oneOfType([object, string])
};

A.displayName = 'A';

/**
 * @function H1
 * @param {String} className
 * @param {Object|String} children
 * @return {React.ReactElement}
 */
const H1 = ({ className, children, ...rest }) => (
	<h1 className={className} {...rest}>
		{children}
	</h1>
);

H1.propTypes = {
	className: string,
	children: oneOfType([object, string])
};

H1.displayName = 'H1';

/**
 * @function H3
 * @param {String} className
 * @param {Object} children
 * @return {React.ReactElement}
 */
const H3 = ({ className, children, ...rest }) => (
	<h3 className={className} {...rest}>
		{children}
	</h3>
);

H3.propTypes = {
	className: string,
	children: oneOfType([object, string])
};

H3.displayName = 'H3';

/**
 * @function ShowHero
 * @description displays a show hero formatted element
 * @param {Object} showData the data return from graphQL query
 * @param {Boolean} [isLink] whether to make the whole element linkable to its show page
 * @param {Boolean} [isPrimaryHeader] determines header tag element for SEO
 * @param {String} [className]
 * @returns {React.ReactElement}
 */
const ShowHero = ({ showData, isLink, className, isPrimaryHeader }) => {
	const { GPP_ENABLED } = useParamToggleContext();
	const { data: gppStatus } = useConsentStatus();
	const [gppValue, setGppValue] = useState(undefined);
	const [gppSid, setGppSid] = useState(undefined);
	const Wrap = isLink ? A : NoWrap;
	const TitleHeader = isPrimaryHeader ? H1 : H3;
	const { edition, translations } = usePageContext();
	const usprivacy = getCookie('usprivacy'); // FW value for gamma _fw_us_privacy
	const csid = useRef(null);
	let uid;
	let fwReferer;
	const gammaVideoId = `eonline-${showData?.promo?.id}`;
	const hlsParams =
		'/?mbr=true&format=redirect&manifest=m3u&format=redirect&Tracking=true&Embedded=true&formats=M3U';
	if (typeof window !== 'undefined') {
		fwReferer = window.location.hostname;
		uid = getFreewheelUserId();
	}

	useEffect(() => {
		csid.current = generateCsid();
	}, []);

	useEffect(() => {
		if (gppStatus?.gppString && gppStatus?.applicableSections) {
			setGppValue(gppStatus.gppString);
			setGppSid(gppStatus?.applicableSections.join(','));
		}
	}, [gppStatus]);

	const customParams = {
		_fw_vcid2: uid,
		_fw_h_referer: fwReferer,
		_fw_player_width: 890,
		_fw_player_height: 498,
		_fw_us_privacy: usprivacy
	};

	if (GPP_ENABLED) {
		customParams.gpp = gppValue;
		customParams.gpp_sid = gppSid;
	}

	if (GPP_ENABLED && !gppValue && (usprivacy === '1YYN' || usprivacy === '1YNN')) {
		return <Loading />;
	}

	const onPlayerReady = (player) => {
		const appMeasurement = new window.AppMeasurement('comcastegeonlineglobaldev');
		// eslint-disable-next-line no-undef
		appMeasurement.visitor = visitor;
		appMeasurement.trackingServer = 'swa.eonline.com';

		const mediaConfig = new window.VodADB.MediaConfig();
		mediaConfig.trackingServer = 'swa.eonline.com/va';
		mediaConfig.playerName = 'E! Gamma Player';
		mediaConfig.channel = 'on-domain';
		mediaConfig.appVersion = '1';
		mediaConfig.debugLogging = false;
		mediaConfig.ssl = true;

		window.VodADB.Media.configure(mediaConfig, appMeasurement);

		const tracker = window.VodADB.Media.getInstance();

		const adBreakObject = window.VodADB.Media.createAdBreakObject('preroll', 1, 0);
		const adMetaDataObject = {
			type: 'preroll',
			assetid: null
		};

		const contentMetadataObject = {
			type: 'content',
			assetid: showData.promo.id || '',
			program: 'E! News Now',
			title: showData.promo.shortTitle || '',
			length: showData.promo.duration || 0,
			isfullepisode: 'n',
			adloadtype: '2',
			airdate: showData.promo.publishDate.split('.')[0] || ''
		};

		const mediaObject = window.VodADB.Media.createMediaObject(
			showData.promo.shortTitle || '',
			showData.promo.id || '',
			showData.promo.duration || '',
			window.VodADB.Media.StreamType.VOD || '',
			window.VodADB.Media.MediaType.Video || ''
		);

		let myInterval;
		let adInterval;
		let adPosition;
		let adTimerFlag;
		let adFlag;

		player.on('ready', (e) => {
			// eslint-disable-next-line no-undef
			ns_.StreamingAnalytics.JWPlayer(player, {
				publisherId: '6035083',
				// eslint-disable-next-line no-useless-escape
				labelmapping: `ns_st_st=\"E! News-vod\", ns_st_pu=\"E!\", ns_st_ge=\"news\", c3=\"EOnline\", ns_st_ci=\"${showData.id}\"`
			});
		});

		player.on('play', (e) => {
			if (e.oldstate === 'paused') {
				tracker.trackPlay();
				window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
				myInterval = setInterval(function () {
					window.nSdkVideoInstance.ggPM(
						'setPlayheadPosition',
						Math.round(player.getPosition())
					);
					tracker.updatePlayhead(Math.round(player.getPosition()));
				}, 1000);
			}
		});

		player.on('pause', (e) => {
			tracker.trackPause();
			clearInterval(myInterval);
		});

		player.on('firstFrame', (e) => {
			adTimerFlag = false;
			if (!adFlag) {
				const mediaObject = window.VodADB.Media.createMediaObject(
					showData.promo.shortTitle || '',
					showData.promo.id || '',
					showData.promo.duration || '',
					window.VodADB.Media.StreamType.VOD || '',
					window.VodADB.Media.MediaType.Video || ''
				);
				tracker.trackSessionStart(mediaObject);
			}
			tracker.trackPlay();
			adFlag = false;
			window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
			myInterval = setInterval(function () {
				window.nSdkVideoInstance.ggPM(
					'setPlayheadPosition',
					Math.round(player.getPosition())
				);
				tracker.updatePlayhead(Math.round(player.getPosition()));
			}, 1000);
		});

		player.on('adBreakEnd', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdBreakComplete);
		});
		player.on('adComplete', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdComplete);
			window.nSdkVideoInstance.ggPM('stop', Math.round(adPosition));
			clearInterval(adInterval);
		});
		player.on('adBreakStart', (e) => {
			tracker.trackSessionStart(mediaObject);
			tracker.trackEvent(window.VodADB.Media.Event.AdBreakStart, adBreakObject);
			adFlag = true;
		});
		player.on('adSkipped', (e) => {
			tracker.trackEvent(window.VodADB.Media.Event.AdSkip);
			clearInterval(adInterval);
		});
		player.on('adPause', (e) => {
			clearInterval(adInterval);
		});
		player.on('adTime', (e) => {
			adPosition = e.position;
			tracker.updatePlayhead(Math.round(adPosition));
			if (adTimerFlag !== true) {
				const adMetadata = {};
				adMetadata[window.VodADB.Media.AdMetadataKeys.Advertiser] = e.client;
				const adObject = window.VodADB.Media.createAdObject('preroll', e.id, 1, e.duration);
				tracker.trackEvent(window.VodADB.Media.Event.AdStart, adObject, adMetadata);
				adTimerFlag = true;
			}
		});
		player.on('adPlay', (e) => {
			window.nSdkVideoInstance.ggPM('loadMetadata', contentMetadataObject);
			adMetaDataObject.assetid = e.id;
			window.nSdkVideoInstance.ggPM('loadMetadata', adMetaDataObject);
			adInterval = setInterval(function () {
				window.nSdkVideoInstance.ggPM('setPlayheadPosition', Math.round(adPosition));
			}, 1000);
		});

		player.on('seek', (e) => {
			window.nSdkVideoInstance.ggPM('stop', Math.round(player.getCurrentTime()));
		});

		player.on('complete', (e) => {
			tracker.trackComplete();
			window.nSdkVideoInstance.ggPM('end', Math.round(player.getCurrentTime()));
			clearInterval(myInterval);
		});

		player.on('error', (e) => {
			if (e.code > 232399 && e.code <= 232600) {
				const fallbackVideo = {
					file: showData.promo.videoUri + hlsParams.split('formats')[0] + 'formats=MPEG4',
					type: 'mp4'
				};
				player.load(fallbackVideo);
				player.play();
			}
		});
	};

	return (
		<Wrap uri={showData.uri}>
			<div data-hook="show-hero" className={`show-hero ${className}`}>
				<div className="show-hero__img-wrap">
					<Thumb
						thumbnail={showData.brandingImage}
						width="1384"
						width2x="1536"
						title={showData.name}
						withLink={false}
						type="2x1"
						className="show-hero__img"
					>
						<div className="show-hero__gradient"></div>
					</Thumb>
				</div>
				<div className="columns show-hero__info">
					<div className="column show-hero__info-column is-full-mobile is-5-tablet is-offset-6-tablet is-4-desktop is-offset-7-desktop text--sm">
						<div className="show-hero__line"></div>
						<TitleHeader
							data-hook="show-hero__title"
							data-testid="show-hero__title"
							className="show-hero__title text--lrg"
						>
							{parseHtml(showData.name)}
						</TitleHeader>
						{showData.tuneIn && showData.tuneIn !== '' && (
							<p data-hook="show-hero__tunein" className="show-hero__tunein">
								<em>{parseHtml(showData.tuneIn)}</em>
							</p>
						)}
						{showData.promo && (
							<Fragment>
								<div className="show-hero__video">
									<GammaPlayer
										jwPlayerLibraryUrl="https://nodeassets.nbcnews.com/jwplayer/jwplayer-8.28.0/jwplayer.js"
										jwPlayerLibraryKey="+9o3ihbMIU8/ixFry35xlHnkQ9tikKg9TU0io1QbWXfpeR0q"
										file={showData.promo.videoUri + hlsParams}
										image={showData?.promo?.thumbnail?.uri}
										width="100%"
										height="259px"
										autostart={false}
										config={{ type: 'hls' }}
										jwPlayerRef={onPlayerReady}
										fwassetid={gammaVideoId}
										advertising={{
											client: 'freewheel',
											freewheel: {
												networkid: 169843,
												serverid: 'https://29773.v.fwmrm.net/ad/g/1',
												profileid: '169843:nbcu_web_jwp_cs_moat_https',
												sectionid: csid.current,
												adManagerUrl: `https://mssl.fwmrm.net/libs/adm/7.0.1/AdManager.js`,
												afid: '127497404',
												sfid: '586754',
												vcid: uid,
												capabilities: {
													flag: {
														dtrd: 'on',
														amcb: 'on',
														sbid: 'on'
													}
												},
												custom: {
													...customParams
												}
											},
											adscheduleid: '12345',
											schedule: {
												adbreak: {
													offset: 'pre',
													tag: 'placeholder_preroll'
												}
											},
											vpaidcontrols: true,
											skipoffset: 3
										}}
									/>
								</div>
								<div
									data-hook="show-hero__video-text"
									className="show-hero__video-text"
								>
									{parseHtml(showData.promo.shortTitle)}
								</div>
							</Fragment>
						)}
						{showData.fullEpisodesLink && (
							<a
								data-hook="show-hero__button"
								data-testid="show-hero__button"
								className="button button--lrg button--full show-hero__button"
								href={showData.fullEpisodesLink}
								target="_blank"
								rel="noopener noreferrer"
								data-analytics-linkname="watch-full-episodes-button"
								data-analytics-linklocation="show-detail-page"
							>
								{edition === 'ap'
									? 'Watch More Here'
									: translations?.WATCH_FULL_EPISODES}
							</a>
						)}
					</div>
				</div>
			</div>
		</Wrap>
	);
};

ShowHero.defaultProps = {
	className: '',
	isLink: false,
	isPrimaryHeader: false
};

ShowHero.propTypes = {
	showData: object.isRequired,
	className: string,
	isLink: bool,
	isPrimaryHeader: bool
};

ShowHero.displayName = 'ShowHero';

export default ShowHero;
